.item__modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.item__modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 20px;
}

.item__modal-image {
  width: 498px;
  height: 498px;
  border-radius: 16px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  border: solid 1px rgba(0, 0, 0, 0.3);
}

.item__card-name {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  padding-top: 20px;
  padding-left: 32px;
  padding-bottom: 12px;
}

.item__weather-type {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  padding-left: 32px;
  padding-bottom: 32px;
}

.item__delete-btn {
  display: flex;
  position: absolute;
  right: 32px;
  bottom: 68px;
  border: none;
  background: transparent;
  font-family: "Cabinet Grotesk", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  color: #ff4d00;
}

.item__delete-btn:hover {
  color: #f80000;
}

.item__delete-btn_visible {
  visibility: visible;
}

.item__delete-btn_hidden {
  visibility: hidden;
}
