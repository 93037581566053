.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__content {
  position: relative;
  padding-left: 28px;
  padding-right: 28px;
  background-color: #fff;
  max-width: 496px;
  width: 100%;
  border-radius: 20px;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
}

.modal__close-btn {
  position: absolute;
  top: 23px;
  right: 31px;
  background: transparent url("../../images/close-button.svg") center no-repeat;
  width: 14px;
  height: 14px;
  border: none;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.modal__close-btn:hover {
  opacity: 60%;
}

.modal__title {
  margin: 28px 0 24px;
}

.modal__form {
  display: flex;
  flex-direction: column;
}

.modal__label {
  margin: 0 0 8px;
  padding: 0;
}

.modal__input {
  border: none;
  border-bottom: 1px solid #000;
  padding: 0;
  margin-bottom: 24px;
  width: 100%;
  height: 20px;
}

.modal__text {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
}

.modal__button {
  margin-bottom: 8px;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  cursor: pointer;
}

.modal__btn-radio {
  cursor: pointer;
}

.modal__btn-radio:checked {
  color: #000;
}

.modal__submit-btn {
  background-color: #000;
  color: #fff;
  width: 124px;
  height: 36px;
  border: none;
  border-radius: 4px;
  margin: 24px 0 36px;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.modal__submit-btn:hover {
  opacity: 60%;
}

.modal__submit-btn_disabled {
  background-color: #000;
  cursor: default;
  color: #808080;
  opacity: 0.3;
}

.modal__alt-button {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5);
  width: 124px;
  height: 36px;
  border: none;
  margin: 24px 0 36px;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

.modal__alt-button:hover {
  opacity: 40%;
}
