.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 32px;
  margin-left: 40px;
  margin-right: 40px;
}

.header__container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.header__logo {
  width: 89px;
  height: 40px;
}

.header__date {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

.header__button {
  border: none;
  background: transparent;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
}

.header__button:hover {
  opacity: 0.6;
}

.header__link {
  text-decoration: none;
  color: #000;
}

.header__name {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
}

.header__avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
}

.header__noavatar {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.2;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}
