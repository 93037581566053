.clothes-section {
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
  max-width: 100vw;
}

.clothes-section__header {
  display: flex;
}

.clothes-section__title {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  padding-right: 20px;
}

.clothes-section__add-btn {
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.2;
  border: none;
  background-color: transparent;
  color: rgba(0, 0, 0, 60%);
  cursor: pointer;
}

.clothes-section__add-btn:hover {
  color: rgba(0, 0, 0, 80%);
}

.clothes-section__items {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.clothes-section__item {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 15px;
}
