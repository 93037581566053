.weather {
  position: relative;
}

.weather__temp {
  position: absolute;
  top: 20px;
  left: 16px;
  font-family: "Cabinet Grotesk", Arial, sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  color: #fff;
}

.weather__image {
  width: 100%;
}
